@import 'src/app/components/content/components/scope/scss/scope';
@import 'src/app/components/content/components/map/scss/map';
@import 'src/app/components/content/components/hours/scss/hours';
@import 'src/app/scss/mixins/mixins';

.main {
  position: relative;

  &::before {
    content: '';
    height: 100%;
    width: 100%;
    opacity: 0.2;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background-image: url('../../../../app/assets/images/dune-2_1914ec48.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  @include display-flex(center, center);
  flex-direction: column;
  padding-top: 1rem;

  section {
    z-index: 2;
    margin: 1rem 0;
    width: 80%;
    display: flex;
  }

  .hours::after {
    @include set-pseudo-element(auto, 0 1rem 1rem 0);
    background-image: url('../../../../app/assets/images/dune-2_1914ec48.jpg');
  }

  .location-details::before,
  .scope::before {
    @include set-pseudo-element(auto, 1rem 0 0 1rem);
    background-image: url('../../../../app/assets/images/dune-2_1914ec48.jpg');
  }
}
