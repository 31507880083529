@import "src/app/scss/variables/variables";
@import "src/app/scss/mixins/mixins";

.contact{
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  padding-top: 1rem;
  @media(max-width: 820px) {
    & > div {
      flex-direction: column;
      align-items: flex-end;
      gap: 1rem;
    }
  }
}
header {
  @include display-flex(center, center);
  height: 12rem;
  flex-direction: column;
  font-family: $roboto-slab;
  font-weight: map-get($font-weights, 'bold');
  color: $text-color;
  text-shadow: $text-shadow;
  @media (max-width: 820px) {
    height: 9rem;
  }
  div {
    width: 70%;
  }

  p {
    &:first-of-type {
      padding-bottom: 1rem;
    }
    font-size: 3rem;
    position: relative;
    @media (max-width: 835px) {
      font-size: 1.5rem;
    }
  }
}

header:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 15rem;
  opacity: 0.3;
  background-image: url("../../../../app/assets/images/dune-2_1914ec48.jpg");
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: cover;
}