@use "sass:map";

$roboto-slab: 'Roboto Slab', serif;
$roboto: 'Roboto', serif;
//$font-weight-bold: 700;
//$font-weight-normal: 400;
$card-background-color: white;
$position-background: #dee7f7ff;
$font-weights: (
        "bold": 700,
        "normal": 400
);
$text-color: #204C8E;
$text-shadow: 0 0.25px 0 $text-color;