@mixin display-flex($alignItems, $justifyContent) {
  display: flex;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin set-pseudo-element($height, $border-radius) {
  content: '';
  height: $height;
  width: 20%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  border-radius: $border-radius;
  @media (max-width: 820px) {
    height: 10rem;
    width: auto;
    border-radius: 1rem 1rem 0 0;
  }
}