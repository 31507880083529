@import 'src/app/scss/variables/variables';
@import 'src/app/scss/mixins/mixins';

.location-details {
  color: $text-color;
  justify-content: center;
  @media (max-width: 820px) {
    flex-direction: column;
  }

  .map-card {
    background-color: $card-background-color;
    border-radius: 0 1rem 1rem 0;
    width: 90%;
    @media (max-width: 820px) {
      border-radius: 0 0 1rem 1rem;
      width: 100%;
    }
  }

  .map-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0 2rem;

    & > div:first-of-type {
      @include display-flex(center, center);
      width: 100%;
      @media (max-width: 820px) {
        justify-content: flex-start;
      }
    }

    h5 {
      font-family: $roboto-slab;
      font-weight: 400;
      font-size: 2.5rem;
      padding: 2rem;
      text-align: center;
    }

    img {
      &.map-directions {
        width: 45rem;
        @media (max-width: 624px) {
          width: 15rem;
        }
        @media (min-width: 625px) and (max-width: 1020px) {
          width: 30rem;
        }
      }
      &.entrance {
        width: 20rem;
        padding-bottom: 2rem;
        @media (max-width: 624px) {
          width: 10rem;
        }
      }
    }
    .pictures {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }
}
