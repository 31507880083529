@import 'src/app/scss/variables/variables';
@import 'src/app/scss/mixins/mixins';
@import '../../articles/scss/_article';

.scope {
  color: $text-color;
  font-family: $roboto-slab;
  @media (max-width: 820px) {
    flex-direction: column;
  }

  .scope-content {
    background-color: $card-background-color;
    width: 80%;
    padding: 2rem 0;
    border-radius: 0 1rem 1rem 0;
    @media (max-width: 820px) {
      width: 100%;
      border-radius: 0 0 1rem 1rem;
    }

    .scope-title {
      text-align: center;
      padding-bottom: 2rem;
    }

    .offer {
      display: flex;
      flex-wrap: wrap;

      article {
        width: 50%;
        @include display-flex(center, center);
        flex-direction: column;
        padding-bottom: 3rem;
        &.driving-instructor {
          align-self: flex-start;
        }
        &.other-jobs {
          width: 100%;
        }
        @media (max-width: 820px) {
          width: 100%;
        }
      }

      @media (max-width: 820px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
      }
      .car {
        background-image: url('../../../../../assets/images/car.jpg');
      }
      .driving-car {
        background-image: url('../../../../../assets/images/driving-car.jpg');
      }
      .excavator {
        background-image: url('../../../../../assets/images/excavator.jpg');
      }
      .other {
        background-image: url('../../../../../assets/images/other-jobs.jpg');
      }
      .pistol-picture {
        background-image: url('../../../../../assets/images/gun.jpg');
      }
    }
  }

  h2,
  h3,
  h4 {
    font-size: 1.5rem;
    padding: 1rem 0;
    width: 90%;
  }

  h2 {
    font-size: 3rem;
    font-weight: map-get($font-weights, 'normal');
    text-shadow: $text-shadow;
  }

  h3 {
    font-weight: map-get($font-weights, 'normal');
    font-size: 2rem;
  }

  h4 {
    text-align: center;
  }
  ul {
    font-family: $roboto;
    list-style: square;
    li {
      padding: 0.5rem 0;
    }
  }
}
