@import 'src/app/scss/variables/variables';
@import 'src/app/scss/mixins/mixins';

.hours {
  color: $text-color;
  font-family: $roboto-slab;
  font-weight: map-get($font-weights, 'normal');
  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }

  .hours-content {
    width: 100%;
    background-color: $card-background-color;
    @include display-flex(center, center);
    flex-direction: column;
    border-radius: 1rem 0 0 1rem;
    @media (max-width: 820px) {
      border-radius: 0 0 1rem 1rem;
    }

    .details {
      width: 100%;
      text-align: center;

      p {
        padding: 0.75rem 0;
        font-size: 1.25rem;
        font-family: $roboto;

        &:nth-child(3),
        &:nth-child(5) {
          font-weight: map-get($font-weights, 'bold');
        }

        &:nth-child(1) {
          font-size: 3rem;
        }
      }

      @media (max-width: 820px) {
        padding: 2rem;
        flex-direction: column;
        @include display-flex(center, center);
      }
    }

    .contact-data {
      display: flex;
      padding-left: 3rem;
      @media (max-width: 820px) {
        flex-direction: column;
        align-items: center;
        padding: 0 2rem;
        gap: 2rem;
      }
    }

    .adress,
    .phone-number {
      display: flex;
      margin: 1rem;
      width: 50%;
      @media (max-width: 820px) {
        width: 100%;
        margin: 0;
      }
    }

    h4 {
      font-weight: map-get($font-weights, 'bold');
      font-size: 2.5rem;
      padding: 2rem;
      text-align: center;
    }

    h5 {
      font-family: $roboto-slab;
      font-weight: map-get($font-weights, 'normal');
      font-size: 1.25rem;
      padding-bottom: 1rem;
    }

    p {
      margin-bottom: 1rem;

      &.position {
        width: 3rem;
        height: 2.25rem;
        border-radius: 0.6rem;
        font-size: 1.5rem;
        background-color: $position-background;
        margin-right: 2rem;
        @include display-flex(center, center);
      }
    }

    strong {
      font-size: 1.25rem;
      font-family: $roboto-slab;
      font-weight: map-get($font-weights, 'bold');
    }

    .contact-details {
      width: 100%;
    }
  }
}
